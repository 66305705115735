import {
    isIOfflineStepDefinition,
    isIOnlineStepDefinition,
    isIStepTruncateIndexedDB,
    StepDefinition,
    IStepDefinitionOptions,
    IOfflineStepDefinition,
    IOnlineStepDefinition,
    IStepTruncateIndexedDB,
    type ISyncOptions
} from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { GroupProgress, type IGroupProgressOptions, type IGroupProgressJSON } from 'o365.pwa.modules.client.steps.GroupProgress.ts';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import { type SyncType } from "o365.pwa.types.ts";

export interface IGroupStepDefinitionOptions extends IStepDefinitionOptions {
    steps: Array<StepDefinition>;
    onBeforeSync?: Function;
    onAfterSync?: Function;
}

export class GroupStepDefinition extends StepDefinition implements IOfflineStepDefinition<GroupProgress>, IOnlineStepDefinition<GroupProgress>, IStepTruncateIndexedDB<GroupProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';
    public readonly IOnlineStepDefinition = 'IOnlineStepDefinition';
    public readonly IStepTruncateIndexedDB = 'IStepTruncateIndexedDB';

    public readonly steps: Array<StepDefinition>;
    public readonly onBeforeSync?: Function;
    public readonly onAfterSync?: Function;

    constructor(options: IGroupStepDefinitionOptions) {
        for (const step of options.steps) {
            if (step instanceof GroupStepDefinition) {
                throw Error('GroupStepDefinition validation failed. Group cannot have a sub group');
            }
        }

        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            vueComponentName: 'GroupProgress',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.GroupProgress.vue');
            },
            subVueComponentsDefinitions: options.steps.map((step) => { return { vueComponentName: step.vueComponentName, vueComponentImportCallback: step.vueComponentImportCallback }; }),
        });

        this.steps = options.steps;
        this.onBeforeSync = options.onBeforeSync;
        this.onAfterSync = options.onAfterSync;
    }

    public toRunStepDefinition(): GroupStepDefinition {
        return new GroupStepDefinition({
            title: this.title,
            steps: this.steps,
            stepId: this.stepId,
            onBeforeSync: this.onBeforeSync,
            onAfterSync: this.onAfterSync,
            dependOnPreviousStep: this.dependOnPreviousStep
        })
    }

    public generateStepProgress(options?: IGroupProgressOptions | IGroupProgressJSON, syncType?: SyncType): GroupProgress {
        return new GroupProgress({
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback,
            stepsProgress: this.steps.map((step: StepDefinition, index: number) => { return step.generateStepProgress(options?.stepsProgress?.[index], syncType); })
        });
    }

    public async syncOnline(options: ISyncOptions<GroupProgress>): Promise<void> {
        await this._runSync(options, async (step: StepDefinition, index: number) => {
            if (isIOnlineStepDefinition(step)) {
                const stepProgress = options.stepProgress.stepsProgress[index];
                
                await step.syncOnline({
                    syncProgress: options.syncProgress,
                    stepProgress: stepProgress,
                    memory: options.memory,
                    currentIndex: options.currentIndex,
                    dependencyMapping: options.dependencyMapping,
                    getPwaVueAppInstance: options.getPwaVueAppInstance,
                    syncRunDefinition: options.syncRunDefinition
                });

                if (stepProgress.syncStatus < SyncStatus.SyncingComplete && options.stepProgress.syncStatus < stepProgress.syncStatus) {
                    options.stepProgress.syncStatus = stepProgress.syncStatus;
                }

                switch (stepProgress.syncStatus as SyncStatus) {
                    case SyncStatus.Syncing:
                        stepProgress.syncStatus = SyncStatus.SyncingComplete;
                        break;
                    case SyncStatus.SyncingWithWarnings:
                        stepProgress.syncStatus = SyncStatus.SyncingCompleteWithWarnings;
                        break;
                    case SyncStatus.SyncingWithErrors:
                        stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
                        break;
                }
            }
        });
    }

    public async syncOffline(options: ISyncOptions<GroupProgress>): Promise<void> {
        await this._runSync(options, async (step: StepDefinition, index: number) => {
            if (isIOfflineStepDefinition(step)) {
                const stepProgress = options.stepProgress.stepsProgress[index];

                await step.syncOffline({
                    syncProgress: options.syncProgress,
                    stepProgress: options.stepProgress.stepsProgress[index],
                    memory: options.memory,
                    currentIndex: options.currentIndex,
                    dependencyMapping: options.dependencyMapping,
                    getPwaVueAppInstance: options.getPwaVueAppInstance,
                    syncRunDefinition: options.syncRunDefinition
                });

                if (stepProgress.syncStatus < SyncStatus.SyncingComplete && options.stepProgress.syncStatus < stepProgress.syncStatus) {
                    options.stepProgress.syncStatus = stepProgress.syncStatus;
                }

                switch (stepProgress.syncStatus as SyncStatus) {
                    case SyncStatus.Syncing:
                        stepProgress.syncStatus = SyncStatus.SyncingComplete;
                        break;
                    case SyncStatus.SyncingWithWarnings:
                        stepProgress.syncStatus = SyncStatus.SyncingCompleteWithWarnings;
                        break;
                    case SyncStatus.SyncingWithErrors:
                        stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
                        break;
                }
            }
        });
    }

    public async truncateData(options: ISyncOptions<GroupProgress>): Promise<void> {
        await this._runSync(options, async (step: StepDefinition, index: number) => {
            if (isIStepTruncateIndexedDB(step)) {
                await step.truncateData({
                    syncProgress: options.syncProgress,
                    stepProgress: options.stepProgress.stepsProgress[index],
                    memory: options.memory,
                    currentIndex: options.currentIndex,
                    dependencyMapping: options.dependencyMapping,
                    getPwaVueAppInstance: options.getPwaVueAppInstance,
                    syncRunDefinition: options.syncRunDefinition
                });
            }
        });
    }

    private async _runSync(options: ISyncOptions<GroupProgress>, runSync: (step: StepDefinition, index: number) => Promise<void>) {
        try {
            const promiseList = new Array<Promise<void>>();

            for (const [index, step] of this.steps.entries()) {
                options.stepProgress.stepsProgress[index].syncStatus = SyncStatus.Syncing;

                promiseList.push(runSync(step, index));
            }

            const promiseListResult = await Promise.allSettled(promiseList);

            for (const [index, promiseResults] of promiseListResult.entries()) {
                if (promiseResults.status === 'rejected') {
                    options.stepProgress.errors.push(promiseResults.reason);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', ''));

                    if (options.stepProgress.syncStatus < SyncStatus.SyncingCompleteWithErrors) {
                        options.stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
                    }

                    continue;
                }

                const result = options.stepProgress.stepsProgress[index];

                if (options.stepProgress.syncStatus < result.syncStatus) {
                    options.stepProgress.syncStatus = result.syncStatus;
                }
            }
        } catch (error: any) {
            options.stepProgress.errors.push(error);
            options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', ''));
            options.stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
        }
    }
}
